import React from 'react';
import styled from 'styled-components';
import Badge from '../../molecules/Badge/Badge';
import BadgeContainer from '../../molecules/Badge/Container/BadgeContainer';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors, colorTransparency } from '../../styles/atoms/colors';
import { padding } from '../../styles/atoms/spacing';
import colorMappings from '../../utils/colorMappings';
import Link from '../../utils/Link';
import Img from '../../utils/OptimizedImage';

const getTagBgColor = tag => {
  const nameValue = tag
    .split('')
    .reduce((curr, char) => curr + char.charCodeAt(0), 0);

  const bgColor = colorMappings[nameValue % colorMappings.length];

  return bgColor;
};

const StoryCard = ({ story }) => (
  <Link to={`/case-studies/${story?.slug}`}>
    <Card>
      <div className="card-content">
        {story?.thumbnail && (
          <Img
            image={story?.thumbnail?.gatsbyImageData}
            src={story?.thumbnail?.url}
            alt={story?.thumbnail?.internalName || ''}
          />
        )}
      </div>
      <div className="overlay">
        <BadgeContainer style={{ marginBottom: '24px' }}>
          {story?.tag.map(_tag => (
            <Badge key={_tag.id} badgeColor={getTagBgColor(_tag.name)}>
              {_tag.name}
            </Badge>
          ))}
        </BadgeContainer>
        <h3>{story?.company?.name}</h3>
        <p className="story-desc">{story?.excerpt}</p>
      </div>
    </Card>
  </Link>
);

export default StoryCard;

export const Card = styled.div`
  position: relative;
  width: 870px;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;

  &::before {
    content: '';
    width: 100%;
    padding-top: 56%;
    display: flex;
  }

  &:hover .overlay {
    background: ${colorTransparency(colors.base.black, 0.2)};
  }

  .card-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 140%;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colorTransparency(colors.base.black, 0.5)};
    ${padding(32)}
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: background-color 0.2s ease-in-out;

    h3 {
      color: ${colors.base.white};
      letter-spacing: 0;
      margin-bottom: 8px;
    }
    p {
      font-weight: 400;
      color: ${colors.base.white};
      &.story-desc {
        display: none;

        ${atMinWidth.md`
          max-width: 440px;
          max-height: calc(100% - 90px);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        `}
      }
    }

    .card-except {
      word-wrap: break-word;
    }
  }
`;
